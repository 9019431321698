import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "CookiesMainView" }
const _hoisted_2 = { class: "BigPlaceholder" }
const _hoisted_3 = { class: "BigContainer" }
const _hoisted_4 = {
  class: "BigHeader",
  style: {}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesTabs = _resolveComponent("CookiesTabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CookiesTabs, {
            Tabs: _ctx.Tabs,
            Default: (_ctx.Tabs[0] as CookiesTabs)
          }, null, 8, ["Tabs", "Default"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["BigBody", _ctx.FillBodyHeight]),
          style: {"overflow":"scroll"}
        }, [
          _createVNode(_component_router_view)
        ], 2)
      ])
    ])
  ]))
}